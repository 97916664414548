import React , {useContext} from 'react';
import { UserContext } from '../context/UserContext';

const Applied = () => {

	const { user } = useContext(UserContext);
   
  return (
        
        <div className="min-h-screen p-1 bg-gray-100 flex items-center justify-center">
        <div className="container max-w-screen-lg mx-auto border-3">
            <div>
                <h2 className="font-semibold text-xl text-gray-600">Application Complete</h2>
                
                <div className="text-xl pt-5"> {user?.name} Thankyou for applying to <br/> this useful government service</div>
             </div>
        </div>
        </div>
  )
}

export default Applied
