import Layout from './components/Layout';
import Register from './components/Register';
import Applied from './components/Applied';


import { BrowserRouter, Routes, Route } from "react-router-dom";

 function App() {


  return (

    <BrowserRouter>
    <Routes>
          <Route path="/" element={<Layout /> } >
              <Route index element={<Register />}></Route>

              <Route path="register" element={<Register />}></Route>
              <Route path="apply" element={<Applied />}></Route>

              <Route path="*" element={''}> </Route>
          </Route>
    </Routes>
  </BrowserRouter>
    

  )
}
export default App;