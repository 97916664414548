import React, {useMemo, useState} from 'react';
import { Outlet } from 'react-router-dom';

import { UserContext } from '../context/UserContext';


const Layout = () => {
    const [user, setUser] = useState({});
    const xUser= useMemo( ()=> ({user, setUser}),[user, setUser]);
   
  
  return (
    <>
     <UserContext.Provider value={xUser}>
      <Outlet />
      </UserContext.Provider>
    </>
  )
}

export default Layout
