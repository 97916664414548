import React, {useState, useEffect, useContext} from 'react';
import { useNavigate} from 'react-router-dom';


import { UserContext } from '../context/UserContext';

const Register = () => {
    const intialValues = {name:"", sex: "Male", age:"", country:""};
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    
    const { setUser} = useContext(UserContext);
   

    const [countries, setCountries]= useState([]);
    const navigate = useNavigate();


    const fetchCountries = async () => {
      const response = await  fetch('https://restcountries.com/v2/region/europe');
      const xdata =  await response.json();
     setCountries(xdata);
     console.log(xdata);
   }

    useEffect (() =>{
          fetchCountries();
    },[]);

   



	const handleInputChange = (e) =>{
        const{name , value} = e.target;
        setFormValues({...formValues, [name]: value});
    }

    

    const handleSubmit = async (e) =>{
      e.preventDefault();
    
      let errors = await validate(formValues);
      setFormErrors(errors);
      
      if(Object.keys(errors).length === 0){

        fetch('http://localhost:3001/api/users', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
				  name: formValues.name,
				  gender:  formValues.sex,
				  age: formValues.age,
                  country: formValues.country
				})
			  })
			  .then(res => res.json())
			  .then(console.log);

        setUser(formValues);
        navigate('apply');
      
      }
        
  }

  const validate =async  (values)=>{
        const errors = {};
       
        if (!values.name){
            errors.name = "Name is required!";
        }

        if(!values.age){
            errors.age= "Age is required!";
       
        }
        if (!values.country){
          errors.country = "Country is required!";
      }


    return errors;
      }

  return (

<div className="min-h-screen p-1 bg-gray-100 flex items-center justify-center">
    
  <div className="container max-w-screen-lg mx-auto">
    <div>
      <h2 className="font-semibold text-xl text-gray-600 mb-6">Some Useful Government Service</h2>
      

    <form onSubmit={handleSubmit}>

                  
    <div className="md:flex md:items-center mb-6">
      <div className="md:w-1/3">
        <label htmlFor="name">Name</label>
      </div>
      <div className="md:w-2/3">
        <input type="text" name="name" id="name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" onChange={handleInputChange} value={formValues.name} />
      </div>
      <div className="text-sm text-red-800">{formErrors.name}</div>
     </div>
     

    <div className="md:flex md:items-center mb-6">
      <div className="md:w-1/3">
        <label htmlFor="sex">Sex</label>
      </div>
      <div className="md:w-2/3">
         <input className="leading-tight" type="radio" name="sex" onChange={handleInputChange} value="Male"  checked={formValues.sex === "Male"} /> <span className="mr-6 text-sm">Male</span>
         <input className="leading-tight" type="radio" name="sex" onChange={handleInputChange} value="Female"  checked={formValues.sex === "Female"} /> <span className="text-sm">Female</span>
      </div>
    </div>

    <div className="md:flex md:items-center mb-6">
      <div className="md:w-1/3">
        <label htmlFor="age">Age</label>
      </div>
      <div className="md:w-1/3">
        <input name="age" id="age" type="number" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" onChange={handleInputChange}  value={formValues.age} />
      </div>
      <div className="text-sm text-red-800">{formErrors.age}</div>
    </div>

    <div className="md:flex md:items-center mb-6">
      <div className="md:w-1/3">
        <label htmlFor="country">Country</label>
      </div>
      <div className="md:w-2/3">
        <select name="country" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" onChange={handleInputChange}>
          <option value="">Choose Country</option>
          { countries.map( (country) => {
           const { name, alpha3Code, numericCode} = country
            return <option value={alpha3Code} key={numericCode}>{name}</option>
          }
           )}
          
          </select>
      </div>
      <div className="text-sm text-red-800">{formErrors.country}</div>
    </div>
    

    <div className="md:flex md:items-center">
      <div className="md:w-1/3"></div>
      <div className="md:w-2/3">
        <button type="submit" className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded">
          Apply
        </button>
      </div>
    </div>
  </form>
  
  </div>
</div>
</div>

  )
}

export default Register
